<template>
  <div id="app">

    <!-- Nav Menu -->
    <Nav />
    <!-- Key Full Path enables to use the same page template with diffrent data and will refresh -->
    <router-view :key="$route.fullPath"/> 

    <!-- Footer Menu -->
    
  </div>
</template>

<script>
import Nav from "@/components/navBar.vue"
export default {
components:{
  Nav
}
}
</script>


<style>


</style>
