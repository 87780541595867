<template>
  <div>
    <div class="car">
      <h1 v-if="$route.path === '/3d'">3D Models</h1>
      <h1 v-if="$route.path === '/VR'">VR</h1>
      <b-carousel id="carousel" :interval="6000" background="#000">
        <!-- Text slides with image -->
        <b-carousel-slide class="slides" :img-src="slide1()"></b-carousel-slide>
        <b-carousel-slide class="slides" :img-src="slide2()"></b-carousel-slide>
        <b-carousel-slide class="slides" :img-src="slide3()"></b-carousel-slide>
        <b-carousel-slide class="slides" :img-src="slide4()"></b-carousel-slide>
      </b-carousel>
    </div>
    <div class="imgText">
      <b-row>
        <b-col>
          <b-container>
            <b-link to="/VR/video"> Simple video loop demo </b-link>
          </b-container>
        </b-col>
        <b-col>
          <b-img :src="require('@/assets/img/vr_vid.jpeg')" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-container>
            <b-link to="/VR/Pan"> Panoramic Scene </b-link>
          </b-container>
        </b-col>
        <b-col>
          <b-img :src="require('@/assets/img/vr_vid.jpeg')" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    slide1() {
      if (this.$route.path === "/3d") {
        var im = require("@/assets/img/sci-fi-head.jpeg");
      } else if (this.$route.path === "/VR") {
        im = require("@/assets/img/hero.png");
      }
      return im;
    },
    slide2() {
      if (this.$route.path === "/3d") {
        var im = require("@/assets/img/build.jpeg");
      } else if (this.$route.path === "/VR") {
        im = require("@/assets/img/meet.webp");
      }
      return im;
    },
    slide3() {
      if (this.$route.path === "/3d") {
        var im = require("@/assets/img/hum3d.jpeg");
      } else if (this.$route.path === "/VR") {
        im = require("@/assets/img/train.webp");
      }
      return im;
    },
    slide4() {
      if (this.$route.path === "/3d") {
        var im = require("@/assets/img/barrel.jpeg");
      } else if (this.$route.path === "/VR") {
        im = require("@/assets/img/vr-build.jpeg");
      }
      return im;
    },
  },
};
</script>

<style scoped>
::v-deep .slides > img {
  height: 700px;
  object-fit: cover;
}
.car {
  background-color: #000;
  position: relative;
}
h1 {
  color: #fff;
  position: absolute;
  z-index: 10;
  top: 150px;
  left: 120px;
  font-size: 4.5rem;
}
#carousel {
  opacity: 0.5;
}
.imgText {
  padding: 50px 0;
}
</style>
