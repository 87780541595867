<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import * as Three from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
export default {
  name: "HelloWorld",
  props: ["picked", "type"],
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      selected: [],
    };
  },
  methods: {
    init: function() {
      let container = document.getElementById("container");

      this.camera = new Three.PerspectiveCamera(
        70,
        container.clientWidth / container.clientHeight,
        0.1,
        500
      );
      if (this.$route.path === "/" || this.$route.path === "/ship") {
        this.camera.rotation.y = (45 / 180) * Math.PI;
        this.camera.position.x = 0;
        this.camera.position.y = 0;
        this.camera.position.z = 180;
      } else if (this.$route.path === "/falcon") {
        this.camera.rotation.y = (45 / 180) * Math.PI;
        this.camera.position.x = 400;
        this.camera.position.y = 0;
        this.camera.position.z = 100;
      } else if (this.$route.path === "/shockwave") {
        this.camera.rotation.y = (45 / 180) * Math.PI;
        this.camera.position.x = 10;
        this.camera.position.y = 0;
        this.camera.position.z = 10;
      }

      var viewPos = this.camera.position;
      var newView = new Three.Vector3();
      newView.copy(viewPos);
      this.camera.localToWorld(newView);

      this.scene = new Three.Scene();
      this.scene.background = new Three.Color("#ddd");

      this.renderer = new Three.WebGLRenderer({ antialias: true });
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      this.renderer.setPixelRatio(window.devicePixalRatio);
      container.appendChild(this.renderer.domElement);



      const controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.addEventListener("change", this.render); // use if there is no animation loop

      if (this.$route.path === "/shockwave") {
        controls.target.set(0, 0, -0.2);
        controls.minDistance = 10;
        controls.maxDistance = 1000;
      } else if (this.$route.path === "/falcon") {
        controls.target.set(0, 0, -0.2);
        controls.minDistance = 10;
        controls.maxDistance = 1000;
      } else {
        controls.target.set(0, 0, -0.2);
        controls.minDistance = 10;
        controls.maxDistance = 1000;
      }
      controls.update();

      this.hLight = new Three.AmbientLight(0x404040, 100);

      this.directionalLight = new Three.DirectionalLight(0xffffff, 3);
      this.directionalLight.position.set(0, 1, 0);
      this.directionalLight.castShadow = true;
      this.scene.add(this.directionalLight);
      if (this.type === "fbx") {
        this.light = new Three.PointLight(0xc4c4c4, 10);
        this.light.position.set(0, 300, 500);
        this.scene.add(this.light);

        this.light1 = new Three.PointLight(0xc4c4c4, 10);
        this.light1.position.set(500, 100, 0);
        this.scene.add(this.light1);

        this.light2 = new Three.PointLight(0xc4c4c4, 10);
        this.light2.position.set(0, 100, -500);
        this.scene.add(this.light2);

        this.light3 = new Three.PointLight(0xc4c4c4, 10);
        this.light3.position.set(-500, 300, 0);
        this.scene.add(this.light3);

        this.fbxLoader = new FBXLoader();
        this.fbxLoader.load(this.picked, (object) => {
          this.scene.add(object);
          this.render();
        });
      } else {
        this.gltfLoader = new GLTFLoader();
        this.gltfLoader.load(this.picked, (gltf) => {
          var modal = gltf.scene;
          this.car = gltf.scene.children[0];
          this.car.scale.set(1, 1, 1);
          this.scene.add(modal);
          const planeMarker = Three.createPlaneMarker();

          this.scene.add(planeMarker);
          this.render();
          this.animate();
        });
      }
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    animate() {
      this.renderer.setAnimationLoop(this.render);
    },
    onSelect() {
      if (this.planeMarker.visible) {
        const model = this.car.clone();

        model.position.setFromMatrixPosition(this.planeMarker.matrix);

        // Rotate the model randomly to give a bit of variation to the scene.
        model.rotation.y = Math.random() * (Math.PI * 2);
        model.visible = true;

        this.scene.add(model);
      }
    },

  },
  mounted() {
    this.init();
    this.render();
    this.animate();
  },
};
</script>

<style scoped>
#container {
  width: 100vw;
  height: 100vh;
}
</style>
