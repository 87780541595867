<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
//import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { ARButton } from "three/examples/jsm/webxr/ARButton.js";
export default {
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      controller: null,
      selected: [],
    };
  },
  methods: {
    init() {
      let container = document.getElementById("container");

      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(
        70,
        window.innerWidth / window.innerHeight,
        0.01,
        40
      );

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.xr.enabled = true;
      container.appendChild(this.renderer.domElement);

      this.hLight = new THREE.AmbientLight(0x404040, 100);

      this.directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      this.directionalLight.position.set(0, 1, 0);
      this.directionalLight.castShadow = true;
      this.scene.add(this.directionalLight);

      this.controller = this.renderer.xr.getController(0);
      this.controller.addEventListener("select", this.onSelect);
      this.scene.add(this.controller);

      container.appendChild(ARButton.createButton(this.renderer));

      window.addEventListener("resize", this.onWindowResize, false);
    },

    // this example is borrowed with slight modifications from three.js samples
    // https://threejs.org/examples/?q=webxr#webxr_ar_cones
    onSelect() {
      if(this.type === 'fbx'){
        this.fbxLoader = new FBXLoader();
        this.fbxLoader.load(this.picked, (object) => {
          this.scene.add(object);
        })
      }
      else {
      this.gltfLoader = new GLTFLoader();
      this.gltfLoader.load(this.mod, (gltf) => {
        var modal = gltf.scene;
        this.car = gltf.scene.children[0];
        this.car.scale.set(0.01, 0.01, 0.01);
        this.scene.add(modal);
        const planeMarker = THREE.createPlaneMarker();
        this.mesh = new THREE.Mesh(planeMarker);

        this.mesh.position
          .set(1000, 1000, 1000)
          .applyMatrix4(this.controller.matrixWorld);
        this.mesh.quaternion.setFromRotationMatrix(this.controller.matrixWorld);

        this.scene.add(this.mesh);
      });
      }
    },

    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate() {
      this.renderer.setAnimationLoop(this.render);
    },

    render() {
      this.renderer.render(this.scene, this.camera);
    },
  },
  computed: {
    mod(modal) {
      if (this.$route.path === "/AR/torpedo") {
        modal = "/torpedo/torpedo.fbx";
      } else if (this.$route.path === "/AR/falcon") {
        modal = "/millenium_falcon/scene.gltf";
      } else if (this.$route.path === "/AR/shockwave") {
        modal = "/shockwave_transformers/scene.gltf";
      } else if (this.$route.path === "/AR/ship") {
        modal = "/free_spaceship_unitron/scene.gltf";
      } 
      return modal;
    },
    type(type) {
      if (this.mod.includes(".fbx")) {
        type = "fbx";
      } else {
        type = "gltf";
      }
      return type;
    },
  },
  mounted() {
    this.init();
    this.render();
    this.animate();
  },
};
</script>

<style scoped>
li {
  list-style: none;
  padding: 20px 0;
}
ul {
  text-align: left;
}
</style>
