<template>
  <div class="home">
    <template v-if="mod === 'info page'">
      <ThreeD />
    </template>
    <template v-else>
      <Mods :picked="mod" :type="type"></Mods>
    </template>
  </div>
</template>

<script>
import Mods from "@/components/mods.vue";
import ThreeD from "@/components/3d-part.vue";
export default {
  name: "App",
  components: {
    Mods,
    ThreeD
  },
  computed: {
    mod(modal) {
      if (this.$route.path === "/") {
        modal = "/torpedo/torpedo.fbx";
      } else if (this.$route.path === "/3d/falcon") {
        modal = "/millenium_falcon/scene.gltf";
      } else if (this.$route.path === "/3d/shockwave") {
        modal = "/shockwave_transformers/scene.gltf";
      } else if (this.$route.path === "/3d/ship") {
        modal = "/free_spaceship_unitron/scene.gltf";
      } else {
        modal = "info page";
      }
      return modal;
    },
    type(type) {
      if (this.mod.includes(".fbx")) {
        type = "fbx";
      } else {
        type = "gltf";
      }
      return type;
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
