import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import AR from "../views/ar-page.vue";
import VR from "../views/vr-page.vue";
import VRPan from "../views/vr-pano.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Torpedo",
    component: Home
  },
  {
    path: "/3d",
    name: "3D Home",
    component: Home
  },
  {
    path: "/VR",
    name: "3D Home",
    component: Home
  },
  {
    path: "/3d/falcon",
    name: "Falcon",
    component: Home
  },
  {
    path: "/3d/shockwave",
    name: "Shockwave",
    component: Home
  },
  {
    path: "/3d/ship",
    name: "Ship",
    component: Home
  },
  {
    path: "/AR",
    name: "AR",
    component: AR
  },
  {
    path: "/AR/torpedo",
    name: "AR Torpedo",
    component: AR
  },
  {
    path: "/AR/falcon",
    name: "AR Falcon",
    component: AR
  },
  {
    path: "/AR/shockwave",
    name: "AR Shockwave",
    component: AR
  },
  {
    path: "/AR/ship",
    name: "AR Ship",
    component: AR
  },
  {
    path: "/VR/video",
    name: "Video",
    component: VR
  },
  {
    path: "/VR/Pan",
    name: "Pan",
    component: VRPan
  },
];

const router = new VueRouter({
  routes,

  // below will force scroll to the toop of the page when using the router
  
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
