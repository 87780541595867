<template>
  <div>
  </div>
</template>

<script>
import * as THREE from "three";
import { VRButton } from "three/examples/jsm/webxr/VRButton.js";
export default {
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
    };
  },
  methods: {
    init() {
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.xr.enabled = true;
      this.renderer.xr.setReferenceSpaceType("local");
      document.body.appendChild(this.renderer.domElement);

      document.body.appendChild(VRButton.createButton(this.renderer));

      //

      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(
        70,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );
      this.camera.layers.enable(1);

      const geometry = new THREE.BoxGeometry(100, 100, 100);
      geometry.scale(1, 1, -1);

      const textures = this.getTexturesFromAtlasFile(
        "/pan/sun_temple_stripe_stereo.jpeg",
        12
      );

      const materials = [];

      for (let i = 0; i < 6; i++) {
        materials.push(new THREE.MeshBasicMaterial({ map: textures[i] }));
      }

      const skyBox = new THREE.Mesh(geometry, materials);
      skyBox.layers.set(1);
      this.scene.add(skyBox);

      const materialsR = [];

      for (let i = 6; i < 12; i++) {
        materialsR.push(new THREE.MeshBasicMaterial({ map: textures[i] }));
      }

      const skyBoxR = new THREE.Mesh(geometry, materialsR);
      skyBoxR.layers.set(2);
      this.scene.add(skyBoxR);

      window.addEventListener("resize", this.onWindowResize);
    },

    getTexturesFromAtlasFile(atlasImgUrl, tilesNum) {
      const textures = [];

      for (let i = 0; i < tilesNum; i++) {
        textures[i] = new THREE.Texture();
      }

      const loader = new THREE.ImageLoader();
      loader.load(atlasImgUrl, function (imageObj) {
        let canvas, context;
        const tileWidth = imageObj.height;

        for (let i = 0; i < textures.length; i++) {
          canvas = document.createElement("canvas");
          context = canvas.getContext("2d");
          canvas.height = tileWidth;
          canvas.width = tileWidth;
          context.drawImage(
            imageObj,
            tileWidth * i,
            0,
            tileWidth,
            tileWidth,
            0,
            0,
            tileWidth,
            tileWidth
          );
          textures[i].image = canvas;
          textures[i].needsUpdate = true;
        }
      });

      return textures;
    },

    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate() {
      this.renderer.setAnimationLoop(this.render);
    },

    render() {
      this.renderer.render(this.scene, this.camera);
    },
  },
  mounted() {
    this.init();
    this.render();
    this.animate();
  },
};
</script>
